import React from 'react';

import Header from "./components/Section/Header";
import Oeffnungszeiten from "./components/Section/Oeffnungszeiten";
import Impressum from "./components/Section/Impressum";
import Getraenkekarte from "./components/Section/Getraenkekarte";
import Saal from "./components/Section/Saal";

function App() {
  return <>
      <Header/>
      <Oeffnungszeiten/>
      <Saal/>
      <Getraenkekarte/>
      <Impressum/>
  </>
}

export default App;
