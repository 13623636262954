import Section from "./Section";
import {H2} from "../../Headlines";
import Paragraph from "../../Paragraph";

export default function Saal() {
    return <>
        <Section id={'saal'} className={'text-center mx-4'}>
            <H2 id={'headline-saal'} className={'font-bold underline mb-4'}>SAALVERMIETUNG</H2>
            <Paragraph className={'text-center'}>Sie können unseren Saal für Geburtstage, Firmenevents, Vereinstreffen oder Feierlichkeiten jeglicher Art mieten.
            Sprechen Sie uns einfach vor Ort an.</Paragraph>
        </Section>
    </>
}