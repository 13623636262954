import React, {ReactNode} from "react";

export default function Paragraph({children, className}: { children: ReactNode, className?: string }) {
    return <>
        <p className={`text-lg leading-8 text-[#26544b] ${className || ''}`}>{children}</p>
    </>
}

export function ImpressumParagraph({children, className}: { children: ReactNode, className?: string }) {
    return <>
        <p className={`bg-[#26544b] text-white ${className || ''}`}>{children}</p>
    </>
}

export function HeadlineParagraph({children}: { children: ReactNode }) {
    return <>
        <p className="text-base font-semibold leading-7 text-green-600">{children}</p>
    </>
}