import Section from "./Section";
import {ImpressumParagraph} from "../../Paragraph";
import {H2} from "../../Headlines";

export default function Impressum() {
    return <>
        <Section id={'impressum'} className={'bg-[#26544b] text-white pb-20 text-center'}>
            <H2 id={'headline-impressum'} className={'text-white underline'}>IMPRESSUM</H2>
            <ImpressumParagraph>Inhaber: Carlo Licata</ImpressumParagraph>
            <ImpressumParagraph>
                Obermarkt 21,
                63571 Gelnhausen
            </ImpressumParagraph>
            <ImpressumParagraph>Telefon: 0160/95877169, Email: licata-edv-service@web.de</ImpressumParagraph>
        </Section>
    </>
}
