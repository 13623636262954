import {H2} from "../../Headlines";
import Section from "./Section";


export default function Getraenkekarte() {
    function beautifyPrice(price: number): string {
        return price.toFixed(2).replace('.', ',').replace(',00', ',-');
    }
    
    function showPrice(price: number | null): string {
        return (price !== null && beautifyPrice(price) + '€') || '-';
    }

    return <>
        <Section id={'getraenkekarte'} className={'text-center pb-48'}>
            <H2 id={'headline-getraenkekarte'} className={'underline'}>UNSER SORTIMENT</H2>
            <div className={'mx-4 md:mx-48 lg:mx-96 mt-8'}>
                <table className="min-w-full divide-y divide-[#26544b]">
                    <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-bold text-[#26544b] sm:pl-0">
                            Anti-Alkoholisches
                        </th>
                        <th scope="col" className="py-3.5 pr-3 text-right text-sm font-semibold text-[#26544b]">
                            normal
                        </th>
                        <th scope="col" className="py-3.5 pl-2 pr-3 text-right text-sm font-semibold text-[#26544b]">
                            groß
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Softdrinks
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    {antiAlkoholisches.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}
                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Fruchtiges
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {fruchtiges.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}

                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Warmes
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {warmes.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table className="min-w-full divide-y mt-20 divide-[#26544b]">
                    <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-bold text-[#26544b] sm:pl-0">
                            Alkoholisches
                        </th>
                        <th scope="col" className="py-3.5 pr-3 text-right text-sm font-semibold text-[#26544b]">
                            normal
                        </th>
                        <th scope="col" className="py-3.5 pl-2 pr-3 text-right text-sm font-semibold text-[#26544b]">
                            groß
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Bier
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    {bier.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}
                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Äppler
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {aeppler.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}

                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Wein
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {wein.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}

                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Schnaps
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {schnaps.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}

                    <tr>
                        <td className="whitespace-nowrap pb-1.5 pt-8 pr-3 text-sm text-left font-semibold underline text-[#26544b] sm:pl-0">
                            Mixe
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {mixe.map((getraenk) => (
                        <tr key={getraenk.name}>
                            <td className="whitespace-nowrap  py-1.5 pl-4 pr-3 text-sm text-left font-medium text-[#26544b] sm:pl-0">
                                {getraenk.name} <span className={'font-extralight'}>{getraenk.groesse}</span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisKlein)}</td>
                            <td className="whitespace-nowrap px-2 py-1.5 text-sm text-right text-[#26544b]">{showPrice(getraenk.preisGross)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </Section>
    </>
}

type getraenk = {
    name: string,
    groesse: string,
    preisKlein: number | null,
    preisGross: number | null,
    allergene: string | null,
}

const antiAlkoholisches: getraenk[] = [
    {name: 'Gelnhäuser Wasser', preisKlein: 0, preisGross: 0, allergene: null, groesse: '0,3l/0,5l'},
    {name: 'Glas Rhönsprudel Classic', preisKlein: 1.7, preisGross: 2.8, allergene: null, groesse: '0,3l/0,5l'},
    {name: 'Glas Cola', preisKlein: 3, preisGross: 5, allergene: null, groesse: '0,3l/0,5l'},
    {name: 'Flasche Spezi', preisKlein: 3, preisGross: null, allergene: '1, koffeinhaltig', groesse: '0,33l'},
    {name: 'Flasche Bitterlemon', preisKlein: 3, preisGross: null, allergene: '1, koffeinhaltig', groesse: '0,2l'},
    {name: 'Glas Orangina', preisKlein: 3, preisGross: 5, allergene: '3, chininhaltig, Bitterstoffe', groesse: '0,3l/0,5l'},
]
const fruchtiges: getraenk[] = [
    {name: 'Glas Orange', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: 'Glas Johannisbeer', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: 'Glas Banane', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: 'Glas Maracuja', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: 'Glas Apfel', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: 'Glas Kirsch', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '0,3l'},
    {name: '... als Schorle', preisKlein: null, preisGross: 3.7, allergene: '', groesse: '0,4l'},
]
const warmes: getraenk[] = [
    {name: 'Tasse Kaffee', preisKlein: 3, preisGross: null, allergene: '', groesse: ''},
    {name: 'Tasse Espresso', preisKlein: 1.5, preisGross: null, allergene: '', groesse: ''},
    {name: 'Tasse Milchkaffee', preisKlein: 3.5, preisGross: null, allergene: '', groesse: ''},
    {name: 'Tasse Cappucino', preisKlein: 3.7, preisGross: null, allergene: '', groesse: ''},
    {name: 'Casajillo', preisKlein: 6, preisGross: null, allergene: '', groesse: ''},
    {name: 'Becher Tee, versch. Sorten', preisKlein: 2.8, preisGross: null, allergene: '', groesse: ''},
]
const bier: getraenk[] = [
    {name: 'Tegernseer hell', preisKlein: 3.3, preisGross: null, allergene: '', groesse: '0,33l'},
    {name: 'Schlappeseppel Pils', preisKlein: 3.3, preisGross: null, allergene: '', groesse: '0,33l'},
    {name: 'Radler', preisKlein: 3.7, preisGross: null, allergene: '', groesse: '0,33l'},
    {name: 'Pilsner Urquell', preisKlein: null, preisGross: 4.7, allergene: '', groesse: '0,5l'},
    {name: 'Keiler Weizen hell', preisKlein: null, preisGross: 4.5, allergene: '', groesse: '0,5l'},
    {name: 'Maisels Kristal', preisKlein: null, preisGross: 4.5, allergene: '', groesse: '0,5l'},
    {name: 'Erdinger Weizen Alkoholfrei', preisKlein: null, preisGross: 4.5, allergene: '', groesse: '0,5l'},
]
const aeppler: getraenk[] = [
    {name: 'Haas Apfelwein, klar', preisKlein: 2.2, preisGross: 3.7, allergene: '', groesse: '0,25l/0,5l'},
]
const wein: getraenk[] = [
    {name: 'Leitz Riesling', preisKlein: null, preisGross: 6.9, allergene: '', groesse: '0,2l'},
    {name: 'San Benedetto Lugana', preisKlein: null, preisGross: 8.9, allergene: '', groesse: '0,2l'},
    {name: 'Diehl Grauburgunder', preisKlein: null, preisGross: 6.9, allergene: '', groesse: '0,2l'},
    {name: 'Cremant', preisKlein: 4, preisGross: null, allergene: '', groesse: '0,1l'},
    {name: 'Rosé Frizzante', preisKlein: 4, preisGross: null, allergene: '', groesse: '0,1l'},
]
const schnaps: getraenk[] = [
    {name: 'Korn', preisKlein: 2.5, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Vodka Finlandia', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Averna', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Havana 3J', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Pastis Duval', preisKlein: 3.2, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Jameson Scotch', preisKlein: 5.9, preisGross: null, allergene: '', groesse: '2cl'},
    {name: 'Tullamore Dew Scotch', preisKlein: 5.2, preisGross: null, allergene: '', groesse: '2cl'},
]
const mixe: getraenk[] = [
    {name: 'Aperol Spritz', preisKlein: null, preisGross: 6.5, allergene: '', groesse: ''},
    {name: 'Vodka Bitterlemon', preisKlein: null, preisGross: 6.5, allergene: '', groesse: ''},
    {name: 'Gin Tonic', preisKlein: null, preisGross: 6.5, allergene: '', groesse: ''},
    {name: 'Wildberry Lillet', preisKlein: null, preisGross: 7.5, allergene: '', groesse: ''},
    {name: 'Cuba Libre', preisKlein: null, preisGross: 7.5, allergene: '', groesse: ''},
]