import Section from "./Section";
import Logo from "../../Logo";
import {H1} from "../../Headlines";

export default function Header() {
    return <>
        <Section id={'home'} className={'bg-[#26544b] pb-20'}>
            <H1 id={'home-headline'} className={'text-center absolute'}>Zur guten Quelle</H1>
            <Logo/>
        </Section>
    </>
}