import Section from "./Section";
import Paragraph from "../../Paragraph";
import {H2} from "../../Headlines";

export default function Oeffnungszeiten() {
    return <>
        <Section id={'oeffnungszeiten'}>
            <H2 id={'headline-oeffnungszeiten'} className={'text-center underline font-bold'}>
                ÖFFNUNGSZEITEN
            </H2>
            <Paragraph className={'text-center font-bold'}>
                Freitag: 17-23 Uhr
                <br/>
                Samstag: 17-23 Uhr
                <br/>
                Sonntag: 13-18 Uhr
            </Paragraph>
            <Paragraph className={'text-center mt-6 px-4'}>"Zur guten Quelle" am Obermarkt in Gelnhausen ist ein gemütlicher Treffpunkt für geselliges Zusammensein.</Paragraph>
        </Section>
    </>
}